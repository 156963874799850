import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { IconFont } from '@/utils/icon';
import { ProtectedRoute, Suspense } from '@components';

const Padding = lazy(() => import('./padding'));
const Interview = lazy(() => import('./interview'));

export default {
  path: 'stationedInFactory',
  element: <Outlet />,
  options: {
    title: '驻场管理',
    permissionKey: 'menu-resident',
    key: 'menu-resident',
    icon: <IconFont type="icon-OTMS_zhuchangguanli" />,
    link: '/stationedInFactory',
  },
  children: [
    {
      path: 'interview',
      element: (
        <ProtectedRoute permissionKey="menu-resident_interview" title="驻场-面试" component={<Suspense><Interview /></Suspense>} />
      ),
      options: {
        title: '驻场-面试',
        key: 'menu-resident_interview',
        icon: null,
        link: '/stationedInFactory/interview',
      },
    },
    {
      path: 'padding',
      element: (
        <ProtectedRoute permissionKey="menu-resident_employment" title="驻场-待入职" component={<Suspense><Padding /></Suspense>} />
      ),
      options: {
        title: '驻场-待入职',
        key: 'menu-resident_employment',
        icon: null,
        link: '/stationedInFactory/padding',
      },
    },
  ],
};
