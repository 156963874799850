/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { globalStyles } from "../../styles";

const { TableContent } = globalStyles;

const MyTable = ({ columns, dataSource, style, prefix, ...rest }) => {
  const { scroll, ...rests } = rest;
  const [tableHeight, setTableHeight] = useState(0); //计算出table的高度；

  useEffect(() => {
    handleResize(); // 初始化时计算一次
    window.addEventListener('resize', handleResize); // 监听窗口的resize事件，动态更新高度
    return () => {
      window.removeEventListener('resize', handleResize); // 清除事件监听器
    };
  }, []);
  
  const handleResize = () => {
    const pageHeight = window.innerHeight; //获取页面高度（视口高度）
    const headerHeight = document.querySelector('.header')?.offsetHeight || 0;// 获取Header元素高度
    const searchHeight = document.getElementById('search-content')?.offsetHeight || 0; //search搜索框高度
    const systemSelectHeight = !!document.getElementById('system-select-box')?.offsetHeight ? document.getElementById('system-select-box')?.offsetHeight + 52 : 0; //结算系统顶部的系统选择框高度
    const tableButtonHeight = document.getElementById('table-header-button')?.offsetHeight || 0; //表格上方的操作容器高度
    const tablePaginationHeight = document.querySelector('.ant-pagination')?.offsetHeight || 0; //表格下方的页码高度
    const tableTheadHeight = document.querySelector('.ant-table-thead')?.offsetHeight || 0; //表头的页码高度
    const realHeight = pageHeight - headerHeight - searchHeight - systemSelectHeight - tableButtonHeight - tablePaginationHeight - tableTheadHeight - 60; //计算真实的高度
    setTableHeight(realHeight); // 减去额外的间距
    console.log('systemSelectHeight', systemSelectHeight);
  };

  const scrolls = {
    x: scroll?.x ? scroll?.x : null,
    y: tableHeight,
  };

  return (
    <TableContent style={style}>
      {prefix && <div id="table-header-button" style={{paddingBottom: '12px', display: 'flex'}}>
        {prefix}
      </div>}
      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={dataSource}
        scroll={scrolls}
        {...rests}
      />
    </TableContent>
  );
};

MyTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.func,
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.arrayOf(PropTypes.object),
};

MyTable.defaultProps = {
  dataSource: [],
};

export default MyTable;
