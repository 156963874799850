import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { IconFont } from '@/utils/icon';
import settlement from '@/modules/settlement';

export default {
  path: 'management',
  element: <Outlet />,
  options: {
    title: '结算管理',
    permissionKey: 'menu-jurisdiction-2323',
    key: 'menu-jurisdiction-232',
    icon: <IconFont type="icon-gongyingshangjiesuan" />,
    link: '/management',
    allowAccess: true,
  },
  children: [
    settlement,
    // supplierSettlement,
  ],
};
