import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const List = lazy(() => import('./list'));

export default {
  path: 'roster',
  element: <Outlet />,
  options: {
    title: '花名册',
    permissionKey: 'menu-member_roster',
    key: 'menu-member_roster',
    link: '/member/roster',
  },
  children: [
    {
      index: true,
      element: (
        <ProtectedRoute
          permissionKey="menu-member_roster"
          title="花名册"
          component={(
            <Suspense>
              <List />
            </Suspense>
          )}
        />
      ),
    },
  ],
};
