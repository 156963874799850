import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const Voucher = lazy(() => import('./voucher'));
const Process = lazy(() => import('./process'));
const Node = lazy(() => import('./node'));
const Document = lazy(() => import('./document'));

export default {
  path: 'settlement',
  element: <Outlet />,
  options: {
    title: '会员结算',
    permissionKey: 'menu-settlement',
    key: 'menu-settlement',
    link: '/management/settlement',
  },
  children: [
    {
      path: 'voucher',
      element: (
        <ProtectedRoute permissionKey="menu-settlement_making" title="结算名单" component={<Suspense><Voucher /></Suspense>} />
      ),
      options: {
        title: '结算名单',
        key: 'menu-settlement_making',
        permissionKey: 'menu-settlement_making',
        icon: null,
        link: '/management/settlement/voucher',
      },
    },
    {
      path: 'document',
      element: (
        <ProtectedRoute permissionKey="menu-settlement_form" title="结算制单" component={<Suspense><Document /></Suspense>} />
      ),
      options: {
        title: '结算制单',
        key: 'menu-settlement_form',
        permissionKey: 'menu-settlement_form',
        icon: null,
        link: '/management/settlement/document',
      },
    },
    {
      path: 'process',
      element: <Outlet />,
      options: {
        title: '结算管理',
        key: 'menu-settlement_memberBatch',
        permissionKey: 'menu-settlement_memberBatch',
        icon: null,
        link: '/management/settlement/process',
      },
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute permissionKey="menu-settlement_memberBatch" title="结算管理" component={<Suspense><Process /></Suspense>} />
          ),
        },
        {
          path: 'node/:settlementBatchId',
          element: <ProtectedRoute permissionKey="menu-settlement_memberBatch" title="结算编辑" component={<Suspense><Node /></Suspense>} />,
          options: {
            title: '结算编辑',
            key: 'menu-settlement_memberBatch',
            permissionKey: 'menu-settlement_memberBatch',
            icon: null,
            link: '/management/settlement/process/node',
            displayInMenu: false,
          },
        },
      ],
    },
  ],
};
