import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const OrderData = lazy(() => import('./orderData'));
const Quit = lazy(() => import('./quit'));
const Setting = lazy(() => import('./setting'));
const OrderProcessing = lazy(() => import('./orderProcessing'));
export default {
  path: 'adminPool',
  element: <Outlet />,
  options: {
    title: '公海',
    permissionKey: 'menu-recruit_highSea',
    key: 'menu-recruit_highSea',
    link: '/recruit/adminPool',
  },
  children: [
    {
      path: 'orderData',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_highSea_orderData" title="订单数据" component={<Suspense><OrderData /></Suspense>} />
      ),
      options: {
        title: '订单数据',
        key: 'menu-recruit_highSea_orderData',
        permissionKey: 'menu-recruit_highSea_orderData',
        icon: null,
        link: '/recruit/adminPool/orderData',
      },
    },
    {
      path: 'quit',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_highSea_resign" title="离职跟进" component={<Suspense><Quit /></Suspense>} />
      ),
      options: {
        title: '离职跟进',
        key: 'menu-recruit_highSea_resign',
        permissionKey: 'menu-recruit_highSea_resign',
        icon: null,
        link: '/recruit/adminPool/quit',
      },
    },
    {
      path: 'setting',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_highSea_refluxTime" title="回流设置" component={<Suspense><Setting /></Suspense>} />
      ),
      options: {
        title: '回流设置',
        key: 'menu-recruit_highSea_refluxTime',
        permissionKey: 'menu-recruit_highSea_refluxTime',
        icon: null,
        link: '/recruit/adminPool/setting',
      },
    },
    {
      path: 'orderProcessing',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_highSea_orderPending" title="订单处理中" component={<Suspense><OrderProcessing /></Suspense>} />
      ),
      options: {
        title: '订单处理中',
        key: 'menu-recruit_highSea_orderPending',
        permissionKey: 'menu-recruit_highSea_orderPending',
        icon: null,
        link: '/recruit/adminPool/orderProcessing',
      },
    },
  ],
};
