import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { IconFont } from '@/utils/icon';
import roster from '@/modules/roster';
import blackList from '@/modules/blackList';
import BlackListCheck from '@/modules/blackListCheck';
import departure from '@/modules/departure';

export default {
  path: 'member',
  element: <Outlet />,
  options: {
    title: '会员',
    permissionKey: 'menu-member',
    key: 'menu-member',
    icon: <IconFont type="icon-huiyuan1" />,
    link: '/member',
  },
  children: [
    roster,
    departure,
    blackList,
    BlackListCheck,
  ],
};
