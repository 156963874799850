import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const SelectOptions = ({
  options,
  tipsDefault,
  ...rest
}) => (
  <Select
    dropdownMatchSelectWidth={false}
    value={`${tipsDefault}(${options.length})`}
    options={options}
    {...rest}
  />
);

SelectOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  tipsDefault: PropTypes.string,
};
SelectOptions.defaultProps = {
  tipsDefault: '已选身份证',
};

export default SelectOptions;
