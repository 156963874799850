import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const Online = lazy(() => import('./online'));
const AddJobs = lazy(() => import('./edit'));
const History = lazy(() => import('./history'));

export default {
  path: 'jobs',
  element: <Outlet />,
  options: {
    title: '岗位管理',
    permissionKey: 'menu-recruit_positionManage',
    key: 'menu-recruit_positionManage',
    link: '/recruit/jobs',
  },
  children: [
    {
      path: 'online',
      element: <Outlet />,
      options: {
        title: '最新岗位',
        key: 'menu-recruit_positionManage_publishManage',
        permissionKey: 'menu-recruit_positionManage_publishManage',
        icon: null,
        link: '/recruit/jobs/online',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="menu-recruit_positionManage_publishManage" component={<Suspense><Online /></Suspense>} />,
        },
        {
          path: ':positionId/edit',
          element: <ProtectedRoute permissionKey="menu-recruit_positionManage_publishManage" title="编辑管理列表" component={<Suspense><AddJobs /></Suspense>} />,
        },
        {
          path: 'create',
          element: <ProtectedRoute permissionKey="menu-recruit_positionManage_publishManage" title="新增岗位" component={<Suspense><AddJobs /></Suspense>} />,
        },
      ],
    },
    {
      path: 'history',
      element: <Outlet />,
      options: {
        title: '历史岗位',
        key: 'menu-recruit_positionManage_historyManage',
        permissionKey: 'menu-recruit_positionManage_historyManage',
        icon: null,
        link: '/recruit/jobs/history',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="menu-recruit_positionManage_historyManage" component={<Suspense><History /></Suspense>} />,
        },
        {
          path: ':positionId/historyEdit',
          element: <ProtectedRoute permissionKey="menu-recruit_positionManage_historyManage" title="编辑管理列表" component={<Suspense><AddJobs /></Suspense>} />,
        },
      ],
    },
  ],
};
