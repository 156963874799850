import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const List = lazy(() => import('./list'));

export default {
  path: 'enterpriseDominant',
  element: <Outlet />,
  options: {
    title: '企业主体',
    permissionKey: 'menu-contract_enterprise_dominant',
    key: 'menu-contract_enterprise_dominant',
    link: '/contract/enterpriseDominant',
  },
  children: [
    {
      index: true,
      element: (
        <ProtectedRoute
          permissionKey="menu-contract_enterprise_dominant"
          title="企业主体"
          component={(
            <Suspense>
              <List />
            </Suspense>
          )}
        />
      ),
    },
  ],
};
