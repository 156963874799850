import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { httpRequest } from '@/utils';

const UploadPictureCard = ({
  values,
  onFormFieldChange,
  name,
  limit = 4,
  fileSizeLimit = null, //上传文件大小限制（以kb计算）
  fileType = "image",
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if(fileType !== 'image') return;
    setFileList(values);
  }, [values]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleChange = async (event) => {
    const { file } = event;
    try {
      //不是image，走二进制流上传
      if(fileType !== 'image'){
        setFileList([...fileList, { ...file, name: file.name, status: 'done' }]);
        onFormFieldChange(name, file);
        return;
      }
      //文件大小超过限制
      if(!!fileSizeLimit && file?.size >= fileSizeLimit * 1024){
        message.error(`上传文件不能超过${fileSizeLimit/1024}M`);
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      const res = await httpRequest.post('common/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.code !== 0) {
        throw new Error(res.msg);
      } else {
        setFileList([...fileList, { url: res.data.url, fileKey: res.data.fileKey, ...file, name: file.name }]);
        onFormFieldChange(name, [...fileList, { ...file, url: res.data.url, fileKey: res.data.fileKey }]);
      }
    } catch (err) {
      console.log('err', err);
      message.error(err.message || '文件上传失败');
    }
  };

  const uploadPictureButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传图片
      </div>
    </div>
  );

  const uploadFileButton = (
    <Button icon={<UploadOutlined />}>上传文件</Button>
  );

  return (
    <>
      <Upload
        listType={fileType === "image" ? "picture-card" : "text"}
        fileList={fileList}
        onPreview={handlePreview}
        accept={fileType === "image" ? "image/*," : ".pdf"}
        customRequest={(event) => {
          handleChange(event);
        }}
        onRemove={(file) => {
          onFormFieldChange(name, fileList.filter((item) => item.uid !== file.uid));
          setFileList(fileList.filter((item) => item.uid !== file.uid));
        }}
      >
        {fileList.length >= limit ? null : fileType === "image" ? uploadPictureButton : uploadFileButton}
      </Upload>
      {fileType === "image" && <Modal open={previewVisible} title="图片预览" footer={null} onCancel={handleCancel}>
        <img
          alt="图片预览"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>}
    </>
  );
};
UploadPictureCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.array.isRequired,
  onFormFieldChange: PropTypes.func,
  name: PropTypes.string,
  limit: PropTypes.number.isRequired,
};

UploadPictureCard.defaultProps = {
  onFormFieldChange: () => {},
  name: '',
};
export default UploadPictureCard;
