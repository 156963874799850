import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { IconFont } from '@/utils/icon';
import company from './company';
import postTask from './postTask';
import latestPosition from './latestPosition';

export default {
  path: 'enterprise',
  element: <Outlet />,
  options: {
    title: '企业',
    permissionKey: 'menu-company',
    key: 'menu-company',
    icon: <IconFont type="icon-qiyemingcheng" />,
    link: '/enterprise',
  },
  children: [
    company,
    latestPosition,
    postTask,
  ],
};
