export default {
  blacklistTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E9%BB%91%E5%90%8D%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  rosterTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E8%8A%B1%E5%90%8D%E5%86%8C%E6%9B%B4%E6%96%B0_%E6%A8%A1%E6%9D%BF.xlsx',
  recruitTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E8%87%AA%E6%8B%9B%E5%9C%A8%E8%81%8C%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  recruitPulsTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_plus%E8%87%AA%E6%8B%9B%E5%9C%A8%E8%81%8C%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  supplierTemplateFalse: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E4%BE%9B%E5%BA%94%E5%95%86%E5%9C%A8%E8%81%8C%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  supplierPulsTemplateFalse: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_plus%E4%BE%9B%E5%BA%94%E5%95%86%E5%9C%A8%E8%81%8C%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  departureTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E7%A6%BB%E8%81%8C%E6%9B%B4%E6%96%B0_%E6%A8%A1%E6%9D%BF.xlsx',
  supplierManagementTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E4%BE%9B%E5%BA%94%E5%95%86%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  lastRecruitTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E6%9C%80%E6%96%B0%E8%87%AA%E6%8B%9B%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  lastRecruitPulsTemplate: ' https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_plus%E6%9C%80%E6%96%B0%E8%87%AA%E6%8B%9B%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  lastSupplierTemplateFalse: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E6%9C%80%E6%96%B0%E4%BE%9B%E5%BA%94%E5%95%86%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  lastSupplierPulsTemplateFalse: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_plus%E6%9C%80%E6%96%B0%E4%BE%9B%E5%BA%94%E5%95%86%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  historyRecruitTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%8E%86%E5%8F%B2%E8%87%AA%E6%8B%9B%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  historyRecruitPulsTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_plus%E5%8E%86%E5%8F%B2%E8%87%AA%E6%8B%9B%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  historySupplierTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%8E%86%E5%8F%B2%E4%BE%9B%E5%BA%94%E5%95%86%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  historySupplierPulsTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%8E%86%E5%8F%B2%E4%BE%9B%E5%BA%94%E5%95%86%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  historySupplierTemplateFalse: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E5%8E%86%E5%8F%B2%E4%BE%9B%E5%BA%94%E5%95%86%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  historySupplierPulsTemplateFalse: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_plus%E5%8E%86%E5%8F%B2%E4%BE%9B%E5%BA%94%E5%95%86%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E6%A8%A1%E6%9D%BF.xlsx',
  blacklistCheckTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F-%E9%BB%91%E5%90%8D%E5%8D%95%E6%A0%A1%E9%AA%8C_%E6%A8%A1%E6%9D%BF.xlsx',
  jobNumberTemplate: 'https://ukon-prod.oss-cn-shenzhen.aliyuncs.com/excel-temp/%E4%BA%91%E5%BD%A9%E4%BA%BA%E8%B5%84_%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F-%E5%AF%BC%E5%85%A5%E5%B7%A5%E5%8F%B7_%E6%A8%A1%E6%9D%BF.xlsx',
};
