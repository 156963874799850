import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const IndividualMember = lazy(() => import('./member'));
const StoreMener = lazy(() => import('./storeMember'));

export default {
  path: 'member',
  element: <Outlet />,
  options: {
    title: '招聘管理',
    key: 'menu-recruit_manage',
    permissionKey: 'menu-recruit_manage',
    link: '/recruit/member',
  },
  children: [
    {
      path: 'self',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_manage_person" title="个人会员数据" component={<Suspense><IndividualMember /></Suspense>} />
      ),
      options: {
        title: '个人会员数据',
        key: 'menu-recruit_manage_person',
        permissionKey: 'menu-recruit_manage_person',
        icon: null,
        link: '/recruit/member/self',
      },
    },
    {
      path: 'store',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_manage_store" title="门店会员数据" component={<Suspense><StoreMener /></Suspense>} />
      ),
      options: {
        title: '门店会员数据',
        key: 'menu-recruit_manage_store',
        permissionKey: 'menu-recruit_manage_store',
        icon: null,
        link: '/recruit/member/store',
      },
    },
  ],
};
