import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { IconFont } from '@/utils/icon';

import enterpriseDominant from '@/modules/enterpriseDominant';
import sealManagement from '@/modules/sealManagement';
import contractTemplate from '@/modules/contractTemplate';
import memberContract from '@/modules/memberContract';

export default {
  path: 'contract',
  element: <Outlet />,
  options: {
    title: '合同管理',
    permissionKey: 'menu-contract',
    key: 'menu-contract',
    icon: <IconFont type="icon-audit" />,
    link: '/contract',
  },
  children: [
    enterpriseDominant, //企业主体
    sealManagement, //印章管理
    contractTemplate, //合同模版
    memberContract, //会员合同
  ],
};
