import styled, { createGlobalStyle } from "styled-components";

const Content = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
`;

//顶部搜索框的背景样式；
const SearchContent = styled.div`
  padding: 20px 20px 0 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 12px;
`;

//底部表格的背景样式；
const TableContent = styled.div`
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  .ant-table-wrapper {
    flex: 1 1 auto;
    .ant-pagination {
      padding: 12px 0 0 0;
      margin: 0;
    }
  }
`;

const BodyHtml = styled.div`
  background-color: #fff;
  border-radius: 4px;
  height: 100%;
  overflow-y: scroll;
`;

export default {
  Content,
  SearchContent,
  TableContent,
  BodyHtml,
};
