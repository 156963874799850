import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const OrderData = lazy(() => import('./orderData'));
const Quit = lazy(() => import('./quit'));
export default {
  path: 'storePublicPool',
  element: <Outlet />,
  options: {
    title: '门店公海',
    permissionKey: 'menu-recruit_storeHighSea',
    key: 'menu-recruit_storeHighSea',
    link: '/recruit/storePublicPool',
  },
  children: [
    {
      path: 'orderData',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_storeHighSea_orderData" title="订单数据" component={<Suspense><OrderData /></Suspense>} />
      ),
      options: {
        title: '订单数据',
        key: 'menu-recruit_storeHighSea_orderData',
        permissionKey: 'menu-recruit_storeHighSea_orderData',
        icon: null,
        link: '/recruit/storePublicPool/orderData',
      },
    },
    {
      path: 'quit',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_storeHighSea_resign" title="离职跟进" component={<Suspense><Quit /></Suspense>} />
      ),
      options: {
        title: '离职跟进',
        key: 'menu-recruit_storeHighSea_resign',
        permissionKey: 'menu-recruit_storeHighSea_resign',
        icon: null,
        link: '/recruit/storePublicPool/quit',
      },
    },
  ],
};
