import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const Review = lazy(() => import('./review'));

export default {
  path: 'blackList',
  element: <Outlet />,
  options: {
    title: '黑名单管理',
    permissionKey: 'menu-member_black',
    key: 'menu-member_black',
    // icon: <Icon.UserDeleteOutlined />,
    link: '/member/blackList',
  },
  children: [
    {
      index: true,
      element: <ProtectedRoute permissionKey="menu-member_black" title="黑名单管理" component={<Suspense><Review /></Suspense>} />,
    },
  ],
};
