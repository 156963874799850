import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const List = lazy(() => import('./list'));
const EditTemplate = lazy(() => import('./editTemplate'));

export default {
  path: 'contractTemplate',
  element: <Outlet />,
  options: {
    title: '合同模版',
    permissionKey: 'menu-contract_contract_template',
    key: 'menu-contract_contract_template',
    link: '/contract/contractTemplate',
  },
  children: [
    {
      index: true,
      element: (
        <ProtectedRoute
          permissionKey="menu-contract_contract_template"
          title="合同模版"
          component={(
            <Suspense>
              <List />
            </Suspense>
          )}
        />
      ),
    },
    {
      path: 'edit',
      element: (
        <ProtectedRoute
          permissionKey="menu-contract_contract_template"
          title="编辑合同模版"
          component={(
            <Suspense>
              <EditTemplate />
            </Suspense>
          )}
        />
      ),
    },
  ],
};
