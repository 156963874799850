/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router, useRoutes,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import routes from './routes';

import './App.less';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const MyRouter = () => {
  const element = useRoutes(routes);
  return element;
};

const App = ({ basename }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router basename={basename || '/'}>
        <MyRouter />
      </Router>
    </QueryClientProvider>
  )
};

App.defaultProps = {
  basename: '/',  // 设置默认的根路径
};

App.propTypes = {
  basename: PropTypes.string,
};

export default App;
