import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

dayjs.locale('zh-cn');

const theme = {
  components: {
    Segmented: {
      itemSelectedBg: "#0d6efa",
      itemSelectedColor: "#fff",
      trackPadding: 4,
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN} theme={theme}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
