import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const Company = lazy(() => import('./company/index'));
const Stores = lazy(() => import('./stores/index'));

export default {
  path: 'dataAccess',
  element: <Outlet />,
  options: {
    title: '数据权限',
    permissionKey: 'menu-jurisdiction_data',
    key: 'menu-jurisdiction_data',
    link: '/jurisdiction/dataAccess',
  },
  children: [
    {
      path: 'company',
      element: (
        <ProtectedRoute permissionKey="menu-jurisdiction_data_company" title="企业数据" component={<Suspense><Company /></Suspense>} />
      ),
      options: {
        title: '企业数据',
        key: 'menu-jurisdiction_data_company',
        icon: null,
        link: '/jurisdiction/dataAccess/company',
        permissionKey: 'menu-jurisdiction_data_company',
      },
    },
    {
      path: 'stores',
      element: (
        <ProtectedRoute permissionKey="menu-jurisdiction_data_store" title="门店数据" component={<Suspense><Stores /></Suspense>} />
      ),
      options: {
        title: '门店数据',
        key: 'menu-jurisdiction_data_store',
        icon: null,
        link: '/jurisdiction/dataAccess/stores',
        permissionKey: 'menu-jurisdiction_data_store',
      },
    },
  ],
};
