import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';
import { IconFont } from '@/utils/icon';

const List = lazy(() => import('./list'));

export default {
  path: 'supplier',
  element: <Outlet />,
  options: {
    title: '供应商管理',
    permissionKey: 'menu-supplier',
    key: 'menu-supplier',
    icon: <IconFont type="icon-gongyingshangguanli1" />,
    link: '/supplier',
  },
  children: [
    {
      index: true,
      element: <ProtectedRoute permissionKey="menu-supplier" title="供应商名单" component={<Suspense><List /></Suspense>} />,
    },
  ],
};
