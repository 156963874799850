import React from 'react';
import { Image } from 'antd';
import { observer } from 'mobx-react-lite';
import authStore from '@/stores/auth';

const containerStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '200px',
};

const Dashboard = () => {
  const imgUrl = authStore.brandUrl || process.env.REACT_APP_BRAND_URL;
  return (
    <div style={containerStyle}>
      <Image preview={false} src={imgUrl} width={700} />
    </div>
  );
};

export default observer(Dashboard);
