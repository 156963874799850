import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const Review = lazy(() => import('./review'));

export default {
  path: 'checkBlackList',
  element: <Outlet />,
  options: {
    title: '黑名单校验',
    permissionKey: 'menu-member_blackVerify',
    key: 'menu-member_blackVerify',
    link: '/member/checkBlackList',
  },
  children: [
    {
      index: true,
      element: <ProtectedRoute permissionKey="menu-member_blackVerify" title="黑名单校验" component={<Suspense><Review /></Suspense>} />,
    },
  ],
};
