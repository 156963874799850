import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import setting from '@/modules/setting';
import dataAccess from '@/modules/dataAccess';
import { IconFont } from '@/utils/icon';

export default {
  path: 'jurisdiction',
  element: <Outlet />,
  options: {
    title: '权限',
    permissionKey: 'menu-jurisdiction',
    key: 'menu-jurisdiction',
    icon: <IconFont type="icon-quanxian" />,
    link: '/jurisdiction',
  },
  children: [
    setting,
    dataAccess,
  ],
};
