import React from 'react';
import { IconFont } from '@/utils/icon';
import { ProtectedRoute } from '@components';
import Dashboard from './review';

export default {
  index: true,
  element: <ProtectedRoute allowAccess title="首页" component={<Dashboard />} />,
  options: {
    title: '首页',
    key: 'menu-index',
    icon: <IconFont type="icon-shouye" />,
    link: '/',
    allowAccess: true,
  },
};
