import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const Record = lazy(() => import('./review'));
const Form = lazy(() => import('./form'));

export default {
  path: 'company',
  element: <Outlet />,
  options: {
    title: '企业管理',
    permissionKey: 'menu-company_manage',
    key: 'menu-company_manage',
    link: '/enterprise/company',
  },
  children: [
    {
      index: true,
      element: <ProtectedRoute permissionKey="menu-company_manage" title="企业管理列表" component={<Suspense><Record /></Suspense>} />,
    },
    {
      path: ':coreCompanyId/edit',
      element: <ProtectedRoute title="编辑企业" permissionKey="menu-company_manage" component={<Suspense><Form /></Suspense>} />,
      options: {
        title: '编辑企业',
        key: 'menu-company_manage1',
        permissionKey: 'menu-company_manage',
        icon: null,
        link: '/company/:coreCompanyId/edit',
        displayInMenu: false,
      },
    },
    {
      path: 'create',
      element: <ProtectedRoute title="新增企业" permissionKey="menu-company_manage" component={<Suspense><Form /></Suspense>} />,
      options: {
        title: '新增企业',
        key: 'menu-company_manage2',
        permissionKey: 'menu-company_manage',
        icon: null,
        link: '/company/create',
        displayInMenu: false,
      },
    },
  ],
};
