import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const Record = lazy(() => import('./review'));
const Form = lazy(() => import('./form'));

export default {
  path: 'latestPosition',
  element: <Outlet />,
  options: {
    title: '最新接单',
    permissionKey: 'menu-company_latest',
    key: 'menu-company_latest',
    link: '/enterprise/latestPosition',
  },
  children: [
    {
      index: true,
      element: <ProtectedRoute title="最新接单" permissionKey="menu-company_latest" component={<Suspense><Record /></Suspense>} />,
    },
    {
      path: ':coreCompanyId/edit',
      element: <ProtectedRoute title="编辑岗位" permissionKey="menu-company_latest" component={<Suspense><Form /></Suspense>} />,
    },
    {
      path: 'create',
      element: <ProtectedRoute title="新增岗位" permissionKey="menu-company_latest" component={<Suspense><Form /></Suspense>} />,
    },
  ],
};
