import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const LastImport = lazy(() => import('./lastImport'));
const HistoryImport = lazy(() => import('./historyImport'));

export default {
  path: 'import',
  element: <Outlet />,
  options: {
    title: '订单导入',
    key: 'menu-recruit_orderImport',
    permissionKey: 'menu-recruit_orderImport',
    link: '/recruit/import',
  },
  children: [
    {
      path: 'lastImport',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_orderImport_latestImport" title="最新订单" component={<Suspense><LastImport /></Suspense>} />
      ),
      options: {
        title: '最新订单',
        key: 'menu-recruit_orderImport_latestImport',
        permissionKey: 'menu-recruit_orderImport_latestImport',
        icon: null,
        link: '/recruit/import/lastImport',
      },
    },
    {
      path: 'historyImport',
      element: (
        <ProtectedRoute permissionKey="menu-recruit_orderImport_historyImport" title="历史订单" component={<Suspense><HistoryImport /></Suspense>} />
      ),
      options: {
        title: '历史订单',
        key: 'menu-recruit_orderImport_historyImport',
        permissionKey: 'menu-recruit_orderImport_historyImport',
        icon: null,
        link: '/recruit/import/historyImport',
      },
    },
  ],
};
