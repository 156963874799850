import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Button, Row, Col, Grid } from "antd";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { searchFieldCol } from "@/constants";
import { globalStyles } from "@/styles";

import FormItem from "../formItem";
const { SearchContent } = globalStyles;
const { useBreakpoint } = Grid;

const Search = ({ onSubmit, fields, extra, initialValues, getForm, style }) => {
  const [form] = Form.useForm();
  const screens = useBreakpoint();

  const [searchButtonSpan, setSearchButtonSpan] = useState(6);

  useEffect(() => {
    if (getForm) {
      getForm(form);
    }
  }, [getForm]);

  useEffect(() => {
    const realSpan = Math.abs(24 - ((fields.length % (24 / searchFieldCol.basic[getCurrentBreakpoint()])) * searchFieldCol.basic[getCurrentBreakpoint()])); //计算了搜索及重置的按钮模块占据的span空间，一直保持于剩余空间的最后
    setSearchButtonSpan(realSpan % 24 === 0 ? 24 : realSpan);
  }, [screens]);

  const getCurrentBreakpoint = () => {
    if (screens.xxl) return 'xxl';
    if (screens.xl) return 'xl';
    if (screens.lg) return 'lg';
    if (screens.md) return 'md';
    if (screens.sm) return 'sm';
    if (screens.xs) return 'xs';
    return 'xxl'; //默认返回xxl，span为6
  };

  return (
    <SearchContent id="search-content" style={style}>
      <Form form={form} onFinish={onSubmit} initialValues={initialValues}>
        <Row gutter={24} style={{}}>
          {fields.map((f) => {
            const basic = f.searchFieldCol
              ? f.searchFieldCol
              : searchFieldCol.basic;
            return f.fields?.length ? (
              <Col span={12}>
                <Form.Item
                  name={f.name}
                  key={f.name}
                  label={f.label}
                  style={{ marginBottom: 0 }}
                >
                  <Row size={20} align="middle">
                    {f.fields.map((cf, index) => (
                      <Col span={8}>
                        {index > 0 && (
                          <span style={{ margin: "0 10px 24px" }}>
                            {f.separator}
                          </span>
                        )}
                        <FormItem
                          key={cf.name}
                          name={cf.name}
                          label={cf.label}
                          type={cf.type}
                          inputProps={{
                            ...cf.inputProps,
                            onChange: (val) => {
                              if (cf?.inputProps?.onChange) {
                                cf.inputProps.onChange(val, cf.name);
                              }
                            },
                          }}
                          labelAlign="right"
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Item>
              </Col>
            ) : (
              <Col {...basic} key={f.name}>
                <FormItem
                  key={f.name}
                  name={f.name}
                  label={f.label}
                  type={f.type}
                  showAllOption={f.showAllOption}
                  inputProps={f.inputProps}
                  labelAlign="right"
                  style={{marginBottom: '20px'}}
                />
              </Col>
            );
          })}
          <Col span={searchButtonSpan}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, marginBottom: '20px'}}>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                htmlType="submit"
              >
                搜索
              </Button>
              <div style={{width: '12px'}}></div>
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  form.resetFields();
                  form.submit();
                }}
              >
                重置
              </Button>
              {extra}
            </div>
          </Col>
          {/* {extra} */}
        </Row>
      </Form>
    </SearchContent>
  );
};

Search.propTypes = {
  onSubmit: PropTypes.func,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      inputProps: PropTypes.object,
    })
  ),
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  getForm: PropTypes.func,
};

Search.defaultProps = {
  onSubmit: () => {},
  fields: [],
  extra: null,
  initialValues: {},
  getForm: () => {},
};

export default Search;
