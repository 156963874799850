import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute, Suspense } from '@components';

const Record = lazy(() => import('./review'));
const Form = lazy(() => import('./form'));

export default {
  path: 'postTask',
  element: <Outlet />,
  options: {
    title: '历史接单',
    permissionKey: 'menu-company_postManage',
    key: 'menu-company_postManage',
    link: '/enterprise/postTask',
  },
  children: [
    {
      index: true,
      element: <ProtectedRoute permissionKey="menu-company_postManage" title="历史接单" component={<Suspense><Record /></Suspense>} />,
    },
    {
      path: ':coreCompanyId/edit',
      element: <ProtectedRoute permissionKey="menu-company_postManage" title="编辑岗位" component={<Suspense><Form /></Suspense>} />,
    },
  ],
};
