import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { IconFont } from '@/utils/icon';
import position from '@/modules/position';
import recruit from '@/modules/recruit';
// import historyImport from '@/modules/historyImport';
import Import from '@/modules/import';
import adminPublicPool from '@/modules/adminPublicPool';
import storePublicPool from '@/modules/storePublicPool';

export default {
  path: 'recruit',
  element: <Outlet />,
  options: {
    title: '招聘',
    permissionKey: 'menu-recruit',
    key: 'menu-recruit',
    icon: <IconFont type="icon-zhaopin" />,
    link: '/recruit',
  },
  children: [
    position,
    recruit,
    Import,
    storePublicPool,
    adminPublicPool,
  ],
};
