import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute, Suspense } from '@components';

const List = lazy(() => import('./list'));

export default {
  path: 'departure',
  element: <Outlet />,
  options: {
    title: '在离职管理',
    permissionKey: 'menu-member_staffStatus',
    key: 'menu-member_staffStatus',
    link: '/member/departure',
  },
  children: [
    {
      index: true,
      element: <ProtectedRoute permissionKey="menu-member_staffStatus" title="在离职管理" component={<Suspense><List /></Suspense>} />,
    },
  ],
};
