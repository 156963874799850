import React, { lazy } from 'react';
import dashboard from '@modules/dashboard';
import stationedInFactory from '@/modules/stationedInFactory';
import enterprise from '@/modules/enterprise';
import supplier from '@/modules/supplier';
import { Suspense } from '@/components';
import recruit from './recruit';
import member from './member';
import jurisdiction from './jurisdiction';
import management from './management';
import contract from './contract';

const NotAuthorized = lazy(() => import('@modules/error/notAuthorized'));

export default [
  dashboard,
  enterprise,
  recruit,
  member,
  stationedInFactory,
  supplier,
  management,
  contract,
  jurisdiction,
  {
    path: 'not-authorized',
    element: <Suspense><NotAuthorized /></Suspense>,
  },
];
