import React, { lazy } from 'react';
import { DefaultLayout } from '@layouts';
import { Suspense } from '@/components';

import appRoutes from './appRoutes';

const Login = lazy(() => import('@modules/login'));
const NotFound = lazy(() => import('@modules/error/notFound'));
const FinishEdit = lazy(() => import('@modules/contractTemplate/finishEdit'));

export default [
  {
    path: '/',
    element: <DefaultLayout />,
    children: appRoutes,
  },
  {
    path: '/login',
    element: <Suspense><Login /></Suspense>,
  },
  {
    path: '*',
    element: <Suspense><NotFound /></Suspense>,
  },
  {
    path: '/contractTemplate/finishEdit/:templateId',
    element: <Suspense><FinishEdit /></Suspense>,
  },
];
